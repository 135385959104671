// Autogenerated file run "yarn mk-images"

export const imageRepository = 
{
  "landing:landingPage.poster": "https://kowoj.mecuate.org/oso/pub/img/caballo/landing.jpg",
  "landing:landingPage.logoMecuate": "https://kowoj.mecuate.org/oso/pub/img/caballo/mecuate-logo.png",
  "landing:landingPage.logoReptilia": "https://kowoj.mecuate.org/oso/pub/img/caballo/reptilia-logo.png",
  "landing:landingPage.logoOlmec": "https://kowoj.mecuate.org/oso/pub/img/caballo/olmec-logo.png",
  "landing:landingPage.logoCultura": "https://kowoj.mecuate.org/oso/pub/img/caballo/cultura-logo.png",
  "landing:landingPage.logoProyectos": "https://kowoj.mecuate.org/oso/pub/img/caballo/sitema-creacion-logo.png"
}

export type iKeyNames = 
  'landing:landingPage.poster'
| 'landing:landingPage.logoMecuate'
| 'landing:landingPage.logoReptilia'
| 'landing:landingPage.logoOlmec'
| 'landing:landingPage.logoCultura'
| 'landing:landingPage.logoProyectos'
