import { GenericObject } from 'models'

export const colors: GenericObject = {
    BACKGROUND: '#020909',
    PRIMARY: '#D9AD5E',
    SECONDARY: '#227C95',
    TERTIARY: '#7632C3',
    TEXT: '#CDD9D5',
    TRANSPARENT: 'transparent',

    SOIL: {
        [100]: '#D9AD5E',
        [200]: '#CB9B54',
        [300]: '#AF7F40',
        [400]: '#9D7138',
        [500]: '#85562D',
        [600]: '#723F1F',
        [700]: '#542D18',
        [800]: '#351D10',
        [900]: '#140A08',
    },
    FOREST: {
        [100]: '#9ad95e',
        [200]: '#85d13d',
        [300]: '#73c229',
        [400]: '#64ac20',
        [500]: '#4a8c0d',
        [600]: '#396e07',
        [700]: '#206000',
        [800]: '#184700',
        [900]: '#013208',
    },
    COLD: {
        [100]: '#5eb3d9',
        [200]: '#37a5d7',
        [300]: '#1293ce',
        [400]: '#007ab8',
        [500]: '#005cb8',
        [600]: '#0a5299',
        [700]: '#004285',
        [800]: '#05318a',
        [900]: '#001466',
    },
    SURFACE: {
        [100]: '#F8FAF7',
        [200]: '#F4F7F2',
        [300]: '#ECEEEB',
        [400]: '#E1E3E0',
        [500]: '#D8DBD7',
        [600]: '#BFC9C3',
        [700]: '#707974',
        [800]: '#3F4945',
        [900]: '#191C1B',
    },
    DARK: {
        [100]: '#454C51',
        [200]: '#2C3135',
        [300]: '#1A1C1E',
    },
    SUCCESS: {
        [100]: '#4DF7AA',
        [200]: '#20D39C',
        [300]: '#00B787',
    },
    DANGER: {
        [100]: '#FF3B46',
        [200]: '#C92B2B',
        [300]: '#A12323',
    },
    WARN: {
        [100]: '#FFDB38',
        [200]: '#FFAE00',
        [300]: '#FF8300',
    },
}
