import { useMemo } from 'react'
import { rectangles, squares } from './dataCollections'
import { cssls_5, cssls_4, cssls_3, sqrFilled } from './DecisionCanvas.styles'

export const Circlelis = () => {
    const cssls4 = cssls_4()
    const cssls5 = cssls_5()
    const filled = sqrFilled()
    return useMemo(
        () => (
            <>
                <path
                    id="CONTORNO_1"
                    className={cssls_3()}
                    d="M755.76,361.15c.93-.9,3.11-2.95,3.76-3.81,3.82-5,3.32-9.72,2.15-18.78C758,309.71,748,275.67,738.59,256.28c-1.48-3-4.85-11.38-10.85-12.09-3.33-.39-7.6.92-5.24-3.22,2.26-4,3.06-5.11,3.06-8.17,0-5.1-1.33-6.35-3.06-11.56-4.63-13.91-18.73-32.87-38.78-58.86-22.51-29.18-80-84.17-103.28-77.79a10.79,10.79,0,0,0-3,1.35c-2.12,1.34.16-3.6-1.91-9.28C572.88,69.35,546,57.27,510.23,46c-39.46-12.4-82.65-22.62-89.89-12.85-.31.41-3,3.37-3.55,4.25s-1.77-3.75-3-5.32c-2.3-2.81-8.5-3.75-14.76-4.45-27.56-3.06-79.4,8.12-79.4,8.12S248,53.21,253,62.14c1.58,2.82,3.77,4.72-.24,3.21a13,13,0,0,0-6.53-.94c-17.92,2-74.87,37.76-98.34,62.26-3,3.11-4.44,7.46-2.91,11.52,1.21,3.23-2.16-.55-2.81-.94-10.3-6.18-62.66,68-74.91,96.57-11.6,27.07-34.52,72.6-24.23,83.62,1.22,1.31,4.47,2.93,3.85,5.52-.81,3.36-6.24,5.81-7.56,7.41a14.43,14.43,0,0,0-3.22,7c-1.85,11.12-5.58,67.9-3.53,101.58,1.69,28,9.93,61.67,15.3,69.39,3.15,4.53,6,7.49,9,9.51a16.85,16.85,0,0,0,6.78,3.07c2.73.43-2.7,5.86-3.65,8.8-5.26,16.13,23.32,63.72,53.21,100.07,23.28,28.31,48.47,49.92,55.11,55.11,4.44,3.47,10.47,3.11,18.95,2.86,3.44-.1-6.2,9.71.55,14,99.55,62.52,203.12,61.71,217.27,62.79,3.82.3,10.43-1.79,11.45-5.28.79-2.68,1.61-2.3,3.22-.69C431,769.77,473,755.77,500,749.21c33.68-8.17,68.38-26.54,100-47,3.1-2,6.37-6.19,6.23-10.92-.08-2.75-.81-4.32-.06-5.17,1.5-1.71,2.3,1.28,5.36,3.32,10.36,6.91,52.14-31.66,79.3-67.85,26.64-35.52,53.35-86,48.93-92.62-3.83-5.74-12-3.73-9.89-4.85,2.69-1.42,5.64-2.18,7.9-3.57,13.27-8.16,13.27-17.35,16.33-29.6,2.33-9.34,9.18-36.74,11.23-62.25,1.82-22.77,3.31-56.18-3.42-63.32C758.89,362.17,754.64,362.24,755.76,361.15Z"
                />
                <g id="squareGroups">
                    {squares.map(({ id, d }) => {
                        let y = Math.random() * 100
                        if (y < 8) {
                            return <path key={id} id={id} d={d} className={cssls4} />
                        } else if (y < 87) {
                            return null
                        }
                        return <path key={id} id={id} d={d} className={filled} />
                    })}
                </g>
                <g id="rectGroups">
                    {rectangles.map(({ id, d }) => {
                        let y = Math.random() * 30
                        if (y < 10) {
                            return <path key={id} id={id} d={d} className={filled} />
                        } else if (y < 25) {
                            return null
                        }
                        return <path key={id} id={id} d={d} className={cssls5} />
                    })}
                </g>
            </>
        ),
        []
    )
}
